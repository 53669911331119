import axios from "axios";
export const SendReportIssue = {
  data: () => ({}),
  methods: {
    async SendReportIssueMethod(
      from_user_email_id,
      to_user_email_id,
      body,
      bank_details
    ) {
      try {
        this.loading = true;
        let data = JSON.stringify({
          command: "reportIssues",
          from_user_email_id,
          to_user_email_id,
          body,
          bank_details,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicReportIssueRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };

        let result = await axios(config);
        this.loading = false;
        return result;
      } catch (error) {
        this.loading = false;
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
    getDynamicReportIssueRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/report_issues"
        : window.location.hostname === "test.commdealdata.com"
        ? "https://a9fb1td1g1.execute-api.us-east-1.amazonaws.com/test/report_issues"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/report_issues"
        : "https://401a68okke.execute-api.us-east-1.amazonaws.com/live/report_issues";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com"
        ? "xSGhbOtd6g2DYh5PhhOJ77HPFFYAQziDCst97SZ7"
        : window.location.hostname === "uat.commdealdata.com"
        ? "iORDwCSXHl670CUuvVuo10kvpylJw7I7KdBqatWc"
        : "w6QQeRLHshapYVbYT94aX9TCJCl75pc37oW3BrOb";
    },
  },
};
