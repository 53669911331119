<template>
  <div class="background">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-row no-gutters>
      <v-col cols="12" md="7" sm="12" xs="12">
        <div class="bg-img">
          <v-card flat tile>
            <div class="leftSide">
              <v-container fluid fill-height color="grey lighten-3">
                <v-layout align-center justify-center>
                  <!-- <v-flex> -->
                  <div class="text-center FontSize65px text-black lineUp"></div>
                  <div class="text-center FontSize45px text-black lineUp2"></div>
                  <!-- </v-flex> -->
                </v-layout>
              </v-container>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="5" sm="12" xs="12">
        <v-row no-gutters align="center" justify="center" :style="{ height: `${VuetifyObj.height}px` }">
          <v-card width="500px" color="transparent" flat class="px-12">
            <div class="fontSize25px fontWeightVariant1 text-white lineHeightVariant1">
              {{ currentTitle }}
            </div>
            <div class="px-0">
              <v-img cover max-width="300" src="@/assets/dth.png"></v-img>
            </div>
            <v-window v-model="loginStepper" :touch="false">
              <v-window-item :value="1">
                <v-card-text class="pa-0">
                  <v-form ref="formSignIn" @submit.prevent lazy-validation>
                    <div class="font-weight-bold text-white my-3">Email ID</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      class="borderRadiusVariant1 text-left"
                      bg-color="white"
                      @keydown.enter="ValidateSignInForm()"
                      @keydown.space="preventLeadingSpace"
                      :rules="[(v) => !!v || 'Email ID is required']"
                      v-model="sign_in.user_email_id"
                    ></v-text-field>
                    <div class="font-weight-bold text-white mb-3">Password</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      class="borderRadiusVariant1"
                      bg-color="white"
                      :type="isPwdLogin ? 'text' : 'password'"
                      @click:append-inner="isPwdLogin = !isPwdLogin"
                      @keydown.space="preventLeadingSpace"
                      @keydown.enter="ValidateSignInForm()"
                      required
                      :append-inner-icon="isPwdLogin ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => (v && v.length >= 8) || 'Password Must Have Min. 8 characters',
                        (v) => /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(v) || 'Requires Special Character',
                      ]"
                      v-model="sign_in.user_password"
                    ></v-text-field>
                  </v-form>
                  <v-card-actions class="pa-0 ma-0 py-0">
                    <v-spacer></v-spacer>
                    <v-btn size="small" variant="text" class="FontSize text-capitalize mt-n12 mr-n2 fontStyle" color="white" @click="loginStepper = 3">Forgot Password ?</v-btn>
                  </v-card-actions>
                  <v-btn :loading="loadingSignIn" variant="flat" rounded="pill" tile class="borderRadiusVariant2 text-capitalize px-6" color="primary" @click="ValidateSignInForm()">Sign In</v-btn>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="2">
                <v-card-text class="pa-0">
                  <v-form ref="formConfirmTrustedDeviceOTP">
                    <div class="fontWeightVariant1 text-white lineHeightVariant1 text-left mt-4">Please enter the OTP that was sent to your registered email: {{ maskedEmail }}</div>
                    <div class="mt-5">
                      <v-otp-input variant="solo" :length="6" :rules="[(v) => !!v || 'OTP is required']" type="number" class="OTPField" v-model="TrustedDeviceOTP"></v-otp-input>
                    </div>
                  </v-form>
                  <v-btn variant="flat" class="text-capitalize mt-4" size="small" :loading="TrustedDeviceOTPLoading" color="primary" @click="ValidateTrustedDeviceOTPMethod()">Verify OTP</v-btn>
                  <v-row class="mt-2">
                    <v-col cols="12" align="left">
                      <div v-if="ResendOTPCounter !== 0" class="text-white">Resend OTP available in {{ ResendOTPCounter }} Seconds</div>
                      <v-btn
                        variant="text"
                        v-if="ResendOTPCounter === 0"
                        :dark="ResendOTPCounter === 0"
                        color="white"
                        :loading="loadingSignIn"
                        class="text-white pa-0 ml-0"
                        :class="ResendOTPCounter === 0 ? 'text-decoration-underline' : ''"
                        @click="signInMethod()"
                        >Resend OTP</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-window-item>
              <v-window-item :value="3">
                <v-card-text class="pa-0 ma-0 pt-3">
                  <v-form ref="formOTP">
                    <div class="font-weight-bold text-white">Please Enter The Registered Email</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      bg-color="white"
                      placeholder="Email"
                      v-model="ForgotPassword.OTPEmail"
                      :rules="[(v) => !!v || 'Email ID is required']"
                      class="mt-4 borderRadiusVariant1"
                    >
                    </v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="pa-0 ma-0 justify-space-between">
                  <v-btn variant="text" color="white" small @click="loginStepper = 1">Back To Sign In</v-btn>
                  <v-btn variant="text" color="white" small :loading="ForgotPasswordOTPLoading" @click="ValidateOTP()">Next</v-btn>
                </v-card-actions>
              </v-window-item>
              <v-window-item :value="4">
                <v-card-text class="pa-0 ma-0">
                  <v-form ref="formConfirmOTP">
                    <div class="text-white font-weight-bold my-1">Enter OTP</div>
                    <v-otp-input length="6" v-model="ForgotPassword.ConfirmOTP" variant="solo" :rules="[(v) => !!v || 'Code is required']" class="OTPField"> </v-otp-input>
                    <div class="text-white font-weight-bold my-3">Enter New Password *</div>
                    <v-text-field
                      bg-color="white"
                      density="compact"
                      variant="outlined"
                      v-model="ForgotPassword.ConfirmNewPassword"
                      :rules="[(v) => !!v || 'Password is required']"
                      class=""
                      :type="isPwdConfirmCode ? 'text' : 'password'"
                      @click:append-inner="isPwdConfirmCode = !isPwdConfirmCode"
                      required
                      :append-inner-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-lock"
                    >
                    </v-text-field>
                    <div class="text-white font-weight-bold my-1">Re-enter New Password *</div>
                    <v-text-field
                      density="compact"
                      variant="outlined"
                      bg-color="white"
                      v-model="ForgotPassword.ReenterNewPassword"
                      :rules="[(v) => !!v || 'Password is required']"
                      class=""
                      :type="isPwdReConfirmCode ? 'text' : 'password'"
                      @click:append-inner="isPwdReConfirmCode = !isPwdReConfirmCode"
                      required
                      :append-inner-icon="isPwdReConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                      prepend-inner-icon="mdi-lock"
                    >
                    </v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="pa-0 ma-0">
                  <v-btn variant="text" color="white" size="small" @click="loginStepper = 1">Back To Sign In</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark variant="flat" size="small" :loading="ForgotPasswordFinalSteploading" @click="ValidateEnteredOTP()">submit</v-btn>
                </v-card-actions>
              </v-window-item>
            </v-window>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { Auth } from "aws-amplify";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    loginStepper: 1,
    TrustedDeviceOTP: "",
    ResendOTPCounter: 0,
    intervalId: null,

    loading: false,
    isPwdLogin: false,
    isPwdConfirmCode: false,
    isPwdReConfirmCode: false,
    ForgotPasswordFinalSteploading: false,
    ForgotPasswordOTPLoading: false,
    TrustedDeviceOTPLoading: false,
    loadingSignIn: false,

    VuetifyObj: {},
    SnackBarComponent: {},
    sign_in: {
      user_email_id: "",
      user_password: "",
      code: "",
    },
    ForgotPassword: {
      OTPEmail: "",
      ConfirmNewPassword: "",
      ReenterNewPassword: "",
      ConfirmOTP: "",
    },
  }),
  watch: {
    "sign_in.user_email_id"(val) {
      this.sign_in.user_email_id = val.toLowerCase();
    },
  },
  computed: {
    currentTitle() {
      switch (this.loginStepper) {
        case 1:
          return window.location.hostname === "dev.commdealdata.com"
            ? "Welcome to DealData - DEV"
            : window.location.hostname === "test.commdealdata.com"
            ? "Welcome to DealData - TEST"
            : window.location.hostname === "uat.commdealdata.com"
            ? "Welcome to DealData - UAT"
            : "Welcome to DealData";
        case 2:
          return window.location.hostname === "dev.commdealdata.com"
            ? "Welcome to DealData - DEV"
            : window.location.hostname === "test.commdealdata.com"
            ? "Welcome to DealData - TEST"
            : window.location.hostname === "uat.commdealdata.com"
            ? "Welcome to DealData - UAT"
            : "Welcome to DealData";
        case 5:
          return "Please check your email";
        default:
          return "Create a DealData account to submit and analyze deals";
      }
    },
    maskedEmail() {
      if (this.sign_in.user_email_id) {
        const [prefix, domain] = this.sign_in.user_email_id.split("@");
        const maskedPrefix = `${prefix.substring(0, 2)}******`;
        return `${maskedPrefix}@${domain}`;
      }
      return this.sign_in.user_email_id;
    },
  },
  mounted() {
    this.VuetifyObj = useDisplay();
    localStorage.clear();
    this.disableHistoryChanges();
  },
  methods: {
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ") e.target.value = e.target.value.replace(/^\s*/, "");
    },
    ResendOTPCounterMethod() {
      this.intervalId = setInterval(() => {
        this.ResendOTPCounter--;
        if (this.ResendOTPCounter <= 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    disableHistoryChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    async ValidateSignInForm() {
      const { valid } = await this.$refs.formSignIn.validate();
      if (valid) {
        this.signInMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async signInMethod() {
      try {
        this.loadingSignIn = true;
        this.user = await Auth.signIn({
          username: this.sign_in.user_email_id,
          password: this.sign_in.user_password,
          attributes: {
            customAuthFlow: "USER_SRP_AUTH",
          },
        });
        this.user = await Auth.signIn(this.sign_in.user_email_id);
        this.$store.commit("SET_USEREMAIL", this.sign_in.user_email_id);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "OTP sent successful",
        };
        this.ResendOTPCounter = 120;
        this.ResendOTPCounterMethod();
        this.loginStepper = 2;
        this.loadingSignIn = false;
      } catch (error) {
        console.log("Error", error);
        if (error.message === "Incorrect username or password.") {
          this.loadingSignIn = true;
          this.SignUpCognitoMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.message,
          };
          this.loadingSignIn = false;
        }
      }
    },
    async ValidateTrustedDeviceOTPMethod() {
      const { valid } = await this.$refs.formConfirmTrustedDeviceOTP.validate();
      if (valid) {
        try {
          this.TrustedDeviceOTPLoading = true;
          await Auth.sendCustomChallengeAnswer(this.user, this.TrustedDeviceOTP)
            .then((res) => {
              this.pushToHomeRouteMethod();
            })
            .catch((err) => {
              this.TrustedDeviceOTP = "";
              if (err.message === "Invalid session for the user.") {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "OTP Expired",
                };
              } else if (err.message === "VerifyAuthChallengeResponse failed with error Incorrect OTP!!.") {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "Incorrect OTP",
                };
              } else {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: err.message,
                };
              }
              this.TrustedDeviceOTPLoading = false;
            });
          this.TrustedDeviceOTPLoading = false;
        } catch (error) {
          console.log("error", error);

          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.message,
          };
          this.loadingPassword = false;
        }
      }
    },
    async ValidateOTP() {
      const { valid } = await this.$refs.formOTP.validate();
      if (valid) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    async SendOTPEmail() {
      this.ForgotPasswordOTPLoading = true;
      await Auth.forgotPassword(this.ForgotPassword.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.ForgotPasswordOTPLoading = false;
          this.loginStepper = 4;
        })
        .catch((err) => {
          this.ForgotPasswordOTPLoading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ValidateEnteredOTP() {
      const { valid } = await this.$refs.formConfirmOTP.validate();
      if (valid) {
        if (this.ReenterNewPassword == this.ConfirmNewPassword) {
          this.ConfirmOTPMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    async ConfirmOTPMethod() {
      this.ForgotPasswordFinalSteploading = true;
      await Auth.forgotPasswordSubmit(this.ForgotPassword.OTPEmail, this.ForgotPassword.ConfirmOTP, this.ForgotPassword.ConfirmNewPassword)
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.ForgotPasswordFinalSteploading = false;
          this.loginStepper = 1;
        })
        .catch((err) => {
          this.ForgotPasswordFinalSteploading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    pushToHomeRouteMethod() {
      this.loadingSignIn = false;
      this.TrustedDeviceOTPLoading = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In",
      };
      sessionStorage.setItem("CURRENT_USER_SESSION", "SIGNED_IN");
      this.$router.push("/MainHomePages/LandingPage");
      setTimeout(() => {}, 2000);
    },
  },
};
</script>

<style>
.background {
  max-height: 100vh !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(132deg, #001943, #023a9c, #0019436e);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
}
.background .bg-img {
  top: 0 !important;
  bottom: 0 !important;
  min-height: 100vh !important;
  z-index: 999 !important;
  opacity: 1 !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* padding: 30px !important; */
  overflow: hidden !important;
  background: #fff !important;
  border-radius: 0 0 21.875rem 0 !important;
}
.FontSize65px {
  font-size: 65px !important;
}
.FontSize45px {
  font-size: 45px !important;
}
.FontSize35px {
  font-size: 35px !important;
}
.GradientText {
  font-size: 35px !important;
  font-weight: 600 !important;
  background-image: linear-gradient(to right, #0354e0, #81a9ef) !important;
  background-size: 100%;
  /* -webkit-background-clip: text !important;
  -moz-background-clip: text !important; */
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.lineUp {
  animation: 3s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.lineUp2 {
  animation: 4s lineup2 ease-out !important;
}
@keyframes lineup2 {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
