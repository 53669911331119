import IndustryClassification from "@/JsonFiles/IndustryClassification.json";
export const industryMaster = {
  data() {
    return {
      industryMastersList: [],
      industryMastersWithAllList: [],
      industryMastersListWithObj:[],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async getindustryMasterListMethod() {
      this.industryMastersList = IndustryClassification.map((commonObj) => commonObj.industry);
      this.industryMastersListWithObj = [...new Set(IndustryClassification.map((commonObj) => commonObj.industry))];
      this.industryMastersWithAllList = IndustryClassification.map((commonObj) => commonObj.industry);
      this.industryMastersWithAllList.unshift("All");
    },
  },
};
