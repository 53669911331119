import axios from "axios";
export const ActivateBankWithController = {
  data: () => ({}),
  methods: {
    async ActivateBankWithControllerMethod(user_details, bank_id, syndication_contact_details, bank_purchasing_preferences_details) {
      try {
        let data = JSON.stringify({
          user_email_id: user_details.user_email_id,
          password: user_details.user_create_password,
          bank_id: bank_id,
          syndication_contact_details,
          purchase_hold_range: bank_purchasing_preferences_details.purchase_hold_range,
          purchase_project_types: bank_purchasing_preferences_details.purchase_project_type,
          purchase_property_classes: bank_purchasing_preferences_details.purchase_property_class,
          purchase_purposes: bank_purchasing_preferences_details.purchase_purpose,
          purchase_industries: bank_purchasing_preferences_details.purchase_industry,
          purchase_other_characteristics: bank_purchasing_preferences_details.purchase_other_characteristics,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicActivateBankQWithControllerRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamic7RestAPIKeyMethod(),
          },
          data: data,
        };

        let result = await axios(config);
        return result;
      } catch (error) {
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
    getDynamicActivateBankQWithControllerRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com" || window.location.hostname == "localhost"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/activatecontrollerandbank"
        : window.location.hostname === "test.commdealdata.com"
        ? "https://a9fb1td1g1.execute-api.us-east-1.amazonaws.com/test/activatecontrollerandbank"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/activatecontrollerandbank"
        : "https://401a68okke.execute-api.us-east-1.amazonaws.com/live/activatecontrollerandbank";
    },
    getDynamic7RestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com" || window.location.hostname == "localhost"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com"
        ? "xSGhbOtd6g2DYh5PhhOJ77HPFFYAQziDCst97SZ7"
        : window.location.hostname === "uat.commdealdata.com"
        ? "iORDwCSXHl670CUuvVuo10kvpylJw7I7KdBqatWc"
        : "w6QQeRLHshapYVbYT94aX9TCJCl75pc37oW3BrOb";
    },
  },
};
