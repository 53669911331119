import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import SignUpPage from "../views/MainHomePages/SignUpPage.vue";
import MainLoginPage from "../views/MainHomePages/MainLoginPage.vue";
const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/BANKONBOARDING/:InvitedMemberDetails",
    name: "MainLoginPage",
    component: MainLoginPage,
  },
  {
    path: "/SIGNUP/:EditorViewerSignUp",
    name: "SignUp",
    component: SignUpPage,
  },
  {
    path: "/MainHomePages/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "about" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/MainHomePages/BankProfiles",
        name: "/MainHomePages/BankProfiles",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/BankProfiles.vue"),
      },
      {
        path: "/MainHomePages/TeamMembers",
        name: "/MainHomePages/TeamMembers",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/TeamMembers.vue"),
      },
      {
        path: "/MainHomePages/MyBankProfile",
        name: "/MainHomePages/MyBankProfile",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/MyBankProfile.vue"),
      },
      {
        path: "/MainHomePages/AuditLogs",
        name: "/MainHomePages/AuditLogs",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/AuditLogs.vue"),
      },
      {
        path: "/MainHomePages/Volume",
        name: "/MainHomePages/Volume",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/Volume.vue"),
      },
      {
        path: "/MainHomePages/IndicativeTerms",
        name: "/MainHomePages/IndicativeTerms",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/IndicativeTerms.vue"),
      },
      {
        path: "/MainHomePages/Drilldown",
        name: "/MainHomePages/Drilldown",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/Drilldown.vue"),
      },
      {
        path: "/MainHomePages/MyDeals",
        name: "/MainHomePages/MyDeals",
        component: () => import(/* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/MyDeals.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

