<template>
  <v-app>
      <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.NavigationEllipseDesign {
  top: 41.3125rem !important;
  gap: 0.43625rem !important;
  opacity: 25% !important;
}
.InvitePageCard {
  display: flex !important;
  width: 58rem !important;
  padding: 2.5rem 0 2.5rem 0 !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 3.5rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem solid var(--secondary-stone, #d2d2d2) !important;
  background: var(--primary-white, #fff) !important;
}

.InvitePageLabelVariant1 {
  color: var(--secondary-navy, #003a9f) !important;
  font-family: Inter, sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.045rem !important;
}

.InvitePageLabelVariant2 {
  width: 24.75rem !important;
  color: var(--primary-black, #000) !important;
  text-align: center !important;
  font-family: Inter, sans-serif !important;
  font-size: 1.75rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.0525rem !important;
}
.InvitePageLabelVariant3 {
  align-self: stretch !important;
  color: var(--primary-black, #000) !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
  text-align: left;
}

.InvitePageLabelVariant4 {
  align-self: stretch !important;
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: Inter, sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 120% !important;
  letter-spacing: -0.02625rem !important;
  text-align: left;
}

.InvitePageButtonVariant1 {
  display: flex !important;
  height: 3.5rem !important;
  padding: 1rem 2rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.625rem !important;

  border-radius: 2.5rem !important;
  background: var(--primary-royal-blue, #0354e0) !important;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25) !important;
}

.InvitePageCardVariant1 {
  display: flex !important;
  width: 14.125rem !important;
  padding: 1.25rem !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 1rem !important;
  align-self: stretch !important;
}
.ButtonClassVariant11 {
  width: 8.4375rem !important;
  height: 2.5rem !important;
  padding: 0.625rem, 2rem, 0.625rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
  color: #0354e0 !important;
}
.ButtonClassVariant12 {
  /* color: var(--primary-royal-blue, #0354e0) !important; */
  color: #fff !important;
  width: 6.125rem !important;
  height: 3.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
}
.ButtonClassVariant13 {
  display: flex !important;
  padding: 0.5rem 2.75rem !important;
  width: 7.875rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1.25rem !important;
  border-radius: 0.75rem !important;
  background: var(--tertiary-light-orange, #fff6e5) !important;
}
.ButtonClassVariant14 {
  display: flex !important;
  padding: 0.5rem 2.75rem !important;
  width: 7.875rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1.25rem !important;
  border-radius: 0.75rem !important;
  background: var(--tertiary-light-orange, #ffcccc) !important;
}
.ButtonClassVariant15 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
  width: 12.375rem !important;
  height: 3.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
  color: #0354e0 !important;
}
.ButtonClassVariant1 {
  color: var(--primary-royal-blue, #0354e0) !important;
  width: 15.125rem !important;
  height: 3.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
}
.ButtonClassVariant2 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
  width: 12.375rem !important;
  height: 3.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
}
.LabelVariant1 {
  text-align: center !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}

.ImageHeightVariant1 {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.ImageHeightVariant2 {
  width: 1.1250625rem !important;
  height: 1.125rem !important;
}

.ImageHeightVariant3 {
  width: 0.97525rem !important;
  height: 1.267875rem !important;
}

.NavigationDrawerWidthVariant1 {
  display: flex !important;
  padding-top: 2.5rem !important;

  align-items: left !important;
  flex-shrink: 0 !important;
  background: var(--secondary-navy, #003a9f) !important;
}
.NavigationDrawerCardVariant2 {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 2.625rem !important;
}
.NavigationDrawerLabelVarient1 {
  color: var(--secondary-sky-blue, #a6c6ff) !important;
  font-family: Inter, sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
  align-items: flex-start !important;
}
.LabelClassVariant6 {
  color: var(--primary-black, #000) !important;
  font-family: Inter, sans-serif !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.06rem !important;
}

.LabelClassVariant5 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: Inter, sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  letter-spacing: -0.02625rem !important;
}

.LabelClassVariant7 {
  color: var(--primary-black, #000) !important;
  text-align: center !important;
  font-family: Inter, sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: --0.045rem !important;
}
.LabelClassVariant8 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}
.LabelClassVariant9 {
  font-family: Inter, sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}

.LabelClassVariant10 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
  color: #707070 !important;
}
.LabelClassVariant11 {
  font-family: Inter, sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.0625rem !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
  color: #707070 !important;
}
.LabelClassVariant12 {
  font-family: Inter, sans-serif !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  line-height: 1.8125rem !important;
  letter-spacing: -0.03em !important;
}
.ButtonClassVariant3 {
  display: flex !important;
  padding: 0.5rem 2.75rem !important;
  width: 7.875rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1.25rem !important;
  border-radius: 0.75rem !important;
  background: var(--tertiary-light-green, #e7fff3) !important;
}

.ButtonClassVariant4 {
  display: flex !important;
  width: 7.875rem !important;
  padding: 0.5rem 1.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1.25rem !important;
  border-radius: 0.75rem !important;
  background: var(--tertiary-light-grey, #f1f8ff) !important;
}
.ButtonClassVariant10 {
  display: flex !important;
  width: 7.875rem !important;
  padding: 0.5rem 1.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1.25rem !important;
  border-radius: 0.75rem !important;
  background: var(--tertiary-light-grey, #fccece) !important;
}
.BoldTextVariant1 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.BoldTextVariant2 {
  color: var(--secondary-charcoal, #2f2f2f) !important;
  font-family: Inter, sans-serif !important;
  font-size: 6px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
  letter-spacing: -0.03rem !important;
}
.ButtonClassVariant5 {
  width: 8.4375rem !important;
  height: 3.5rem !important;
  padding: 0.625rem, 2rem, 0.625rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
  color: #0354e0 !important;
}

.ButtonClassVariant6 {
  display: flex !important;
  padding: 0.5rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.625rem !important;
  border-radius: 2.5rem !important;
  border: 0.0625rem solid var(--secondary-stone, #d2d2d2) !important;
  background: var(--primary-white, #fff) !important;
}
.ButtonClassVariant7 {
  width: 12.5rem !important;
  height: 3.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
}

.ButtonClassVariant8 {
  width: 13.25rem !important;
  height: 3.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  gap: 0.625rem !important;
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: center !important;
}

.ButtonClassVariant9 {
  font-family: Inter, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.1875rem !important;
  letter-spacing: -0.03em !important;
  text-align: right !important;
  color: #0354e0 !important;
}

.tableClassHeader table th {
  font-size: 0.9375rem !important;
}
.tableClassVariant td {
  padding: 1.875rem !important;
  justify-items: space-between !important;
  height: 6.125rem !important;
}
.TextFieldVariant {
  width: 14.0625rem !important;
  height: 3rem !important;
  padding: 0.75rem, 1rem, 0.75rem, 1rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem !important;
  gap: 0.5rem !important;
}
.fontSize10px {
  font-size: 0.635rem !important;
}
.fontSize12px {
  font-size: 0.75rem !important;
}
.fontSize14px {
  font-size: 0.875rem !important;
}
.fontSize15px {
  font-size: 0.9375rem !important;
}
.fontSize16px {
  font-size: 1rem !important;
}
.fontSize18px {
  font-size: 1.125rem !important;
}
.fontSize20px {
  font-size: 1.25rem !important;
}
.fontSize25px {
  font-size: 1.5625rem !important;
}
.fontSize30px {
  font-size: 1.875rem !important;
}
.fontSize40px {
  font-size: 2.5rem !important;
}

.fontWeightVariant1 {
  font-weight: 500 !important;
}

.fontWeightVariant2 {
  font-weight: 900 !important;
}
.fontWeightVariant3 {
  font-weight: 700 !important;
}
.lineHeightVariant1 {
  line-height: 1.875rem !important;
}
.lineHeightVariant2 {
  line-height: 2.5rem !important;
}

.borderRadiusVariant1 {
  border-radius: 0.4375rem !important;
}

.borderRadiusVariant2 {
  border-radius: 1.25rem !important;
}
.dottedBorder {
  border: 0.0625rem dashed black !important;
}
.borderVariant1 {
  border: 0.0625rem dashed rgb(0, 0, 0) !important;
}
.greyColorVariant1 {
  background-color: #d5d1d1 !important;
}
.cursorPointer {
  cursor: pointer !important;
}
.fontStyleUnderline {
  text-decoration: underline;
}
.maxWidthVariant1 {
  max-width: 11.25rem !important;
}
.maxWidthVariant2 {
  max-width: 13.125rem !important;
}
.maxWidthVariant3 {
  max-width: 13.75rem !important;
}
.maxWidthVariant4 {
  max-width: 7.5rem !important;
}
.scroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 0.75rem !important;
  height: 0.75rem !important;
  background-color: #ffffff;
  color: red;
}
.scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #0f99da !important;
  border: solid 0.1875rem #e6e6e6 !important;
  border-radius: 0.4375rem !important;
}

.custom-scrollbar {
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  scrollbar-color: #003a9f00 #f5f5f500 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f500 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #003a9f !important;
  border-radius: 0.25rem !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #003a9f !important;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}

.btn-bottom-center {
  position: absolute !important;
  bottom: 6.25rem;
  left: 50%;
  transform: translateX(-50%);
}
.field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 0.75rem !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 0.75rem;
}
.dropdown_field.v-select .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-select .v-input_control .v-inputslot .v-selectslot .v-select_selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}

.tableClassVariant1 tr:nth-child(even) {
  background: rgba(144, 197, 244, 0.539) !important;
}
.tableClassVariant1 th {
  background: rgba(87, 127, 229, 0.991) !important ;
}
.scroll-container {
  height: 25rem !important;
  overflow: auto !important;
  scrollbar-width: none !important;
  scrollbar-color: transparent !important;
}

.scroll-container::-webkit-scrollbar {
  width: 0.1em !important;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
.buttonClassVarient1 {
  width: 12.5625rem !important;
  height: 3.5rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem, 2rem, 1rem, 2rem;
  gap: 0.625rem;
}
.buttonClassVarient2 {
  width: 12.375rem !important;
  height: 3.5rem !important;
  padding: 1rem 2rem 1rem 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
}
.buttonClassVarient3 {
  width: 7.8125rem !important;
  height: 2.1875rem !important;
  padding: 0.5rem, 1.5rem, 0.5rem, 1.5rem !important;
  border-radius: 0.75rem !important;
  gap: 1.25rem !important;
}
.buttonClassVarient4 {
  width: 18rem !important;
  height: 5rem !important;
  padding: 8rem, 24rem, 8rem, 24rem !important;
  border-radius: 40rem !important;
  gap: 20rem !important;
}
.buttonClassVarient5 {
  font-weight: 600 !important;
  font-size: 1rem !important;
  width: 22.5rem !important;
  height: 3.5rem !important;
  padding: 6px, 2rem, 1rem, 2rem !important;
  border-radius: 2.5rem !important;
  gap: 0.625rem !important;
}
.buttonClassVarient6 {
  font-weight: 600 !important;
  font-size: 1rem !important;
  letter-spacing: -3% !important;
  width: 158px !important;
  height: 3.5rem !important;
  padding: 0.625rem, 2rem, 0.625rem, 2rem !important;
  border-radius: 2.5rem !important;
  align-content: center !important;
  gap: 0.625rem !important;
}
.buttonClassVarient7 {
  width: 13.25rem !important;
  height: 3.5rem !important;
  border-radius: 2.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.5rem !important;
}
.buttonClassVarient8 {
  width: 6.25rem !important;
  height: 2.875rem !important;
  border-radius: 2.5rem !important;
  border: 0.0625rem solid #d2d2d2 !important;
  padding: 1rem, 2rem, 1rem, 2rem !important;
  gap: 0.5rem !important;
}
.textVarient1 {
  font-size: 2rem !important;
  font-weight: 600 !important;
  line-height: 2.420625rem !important;
  letter-spacing: -3% !important;
  color: #000000 !important;
}
.cardContentVarient1 {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  line-height: 1.5125rem !important;
  letter-spacing: -3% !important;
  /* width: 53.3125rem !important; */
  height: 5rem !important;
  border-radius: 0.5rem !important;
  border: 0.0625rem !important;
  justify-content: space-between !important;
  padding: 1.25rem !important;
  background-color: #f1f8ff !important;
}
.cardContentVarient2 {
  border-radius: 0.5rem !important;
  padding: 2.5rem !important;
  gap: 3.5rem !important;
}
.cardContentVarient3 {
  width: 13.0625rem !important;
  height: 23.9375rem !important;
  top: 2.5rem !important;
  left: 2.5rem !important;
  gap: 2.625rem !important;
}
.dropDowVarient1 {
  width: 8.5rem !important;
  height: 3rem !important;
  gap: 0.5rem !important;
}
.tabNegativeMargin {
  margin-top: -7.5rem !important;
}
.SimpleTableClass table td {
  border: 0.0625rem solid #000 !important;
}
.OTPField.v-otp-input {
  display: contents !important;
}
.OTPField .v-otp-input__content {
  padding: 0 !important;
  max-width: 500px !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset !important;
  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset !important;
  border-radius: 10px !important;
  border: none !important;
}
.CustomizedCardActions.v-card-actions{
  min-height: 40px !important;
}
.TextFieldVariant1 .v-field__input {
  font-size: 12px !important;
}
.CardOutlined.v-card--variant-outlined{
  border: thin solid #e3e3e3 !important;
}
.CardOutlined1.v-btn--variant-outlined{
  border: thin solid #e3e3e3 !important;
}
</style>

