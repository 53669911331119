<template>
  <div>
    <v-card v-if="overlay" :height="VuetifyObj.height"  flat>
      <v-row align="center" justify="center" :style="{ height: `${VuetifyObj.height}px` }">
        <v-col cols="12" align="center">
          <v-overlay :model-value="overlay"> </v-overlay>
          <v-img width="150px" src="@/assets/DealHour_Loader.gif"></v-img>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <!-- <v-overlay :model-value="overlay">
    <v-row align="center" justify="center" class="d-flex justify-center" v-if="overlay" :style="{ height: `${VuetifyObj.height}px` }">
      <v-col cols="12" align="center">
        <v-card>
          ko
          <v-img width="150px" src="@/assets/DealHour_Loader.gif"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-overlay> -->
</template>
<script>
import { useDisplay } from "vuetify/lib/framework.mjs";
export default {
  props: {
    overlay: Boolean,
    count: Number,
  },
  data: () => ({
    VuetifyObj: {},
  }),
  mounted() {
    this.VuetifyObj = useDisplay();
  },
};
</script>
