<template>
  <v-snackbar absolute  v-model="SnackBarComponent.SnackbarVmodel" :color="SnackBarComponent.SnackbarColor" location="top">
    <div class="text-center">{{ SnackBarComponent.SnackbarText }}</div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },
};
</script>
